<template>
  <AppSwiperItem :item="item" related-mode />
</template>

<script>
import AppSwiperItem from '@/components/partials/elements/AppSwiperItem';

export default {
  name: 'AppRelatedSmallSectionItem',
  components: { AppSwiperItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
