<template>
  <div class="app-related-small-section">
    <h3 class="app-related-small-section__header">Related</h3>

    <ul class="app-related-small-section__items">
      <li v-for="item in items" :key="item.id" class="app-related-small-section__item">
        <AppRelatedSmallSectionItem :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import AppRelatedSmallSectionItem from '@/components/partials/AppRelatedSmallSectionItem';

export default {
  name: 'AppRelatedSmallSection',
  components: { AppRelatedSmallSectionItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-related-small-section {
  margin-top: 70px;

  &:not(:last-child) {
    margin-bottom: 70px;
  }

  &__header {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2.14px;
    line-height: 38px;
    text-transform: uppercase;
    color: #363636;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    flex: 1 1 100%;

    &:first-child {
      margin-bottom: 30px;
    }

    @media (min-width: 576px) {
      flex: 1;

      &:first-child {
        margin-bottom: 0;
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 15px;
      }
    }

    @media (min-width: 768px) {
      &:first-child {
        margin-right: 25px;
      }
      &:last-child {
        margin-left: 25px;
      }
    }
  }
}
</style>
