<template>
  <div class="story-content">
    <template v-for="(part, idx) in story.descriptionParts">
      <StorySlides
        v-if="part.slides && part.slides.length"
        :key="`${story.id}_${part.__typename}_${idx}`"
        data-aos="fade-up"
        :slides="part.slides"
      />
      <AppRelatedSmallSection
        v-else-if="part.relatedRecords && part.relatedRecords.length"
        :key="`${story.id}_${part.__typename}_${idx}`"
        class="story-content-part container"
        data-aos="fade-up"
        :items="part.relatedRecords"
      />
      <StoryDescriptionTextPart v-else :key="`${story.id}_${part.__typename}_${idx}`" :text="part.text" />
    </template>
  </div>
</template>

<script>
import AppRelatedSmallSection from '@/components/partials/AppRelatedSmallSection';
import StorySlides from '@/components/partials/StorySlides';
import StoryDescriptionTextPart from '@/components/partials/StoryDescriptionTextPart';

export default {
  name: 'StoryDefaultTemplate',
  components: { AppRelatedSmallSection, StorySlides, StoryDescriptionTextPart },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
};
</script>
