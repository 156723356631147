<template>
  <div class="story-slides" :style="cssProps">
    <swiper :options="options" ref="swiperElement">
      <swiper-slide v-for="slide in slides" :key="`story-slide-${slide.id}`" class="story-slides__slide story-slide">
        <figure>
          <img :src="slide.media.url" :alt="slide.title" />
          <figcaption class="story-slide__title">{{ slide.title }}</figcaption>
        </figure>
      </swiper-slide>

      <div
        slot="pagination"
        class="swiper-pagination story-slides__pagination story-slides-pagination"
        :style="{ left: `${activeSlideLeftPos}px` }"
      />

      <button
        slot="button-prev"
        class="story-slides__nav-btn story-slides__nav-btn--prev"
        :style="{ left: `${prevNavBtnLeftPos}px` }"
      />
      <button
        slot="button-next"
        class="story-slides__nav-btn story-slides__nav-btn--next"
        :style="{ left: `${nextNavBtnLeftPos}px` }"
      />
    </swiper>
  </div>
</template>

<script>
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';

const SLIDES_PAGINATION_ELEM_WIDTH = 70;
const SLIDES_NAV_BTN_SIZE = 20;

export default {
  name: 'StorySlides',
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      options: {
        initialSlide: 0,
        speed: 1000,

        centeredSlides: true,
        slidesPerView: 1.71,
        spaceBetween: 24,

        breakpoints: {
          1600: {
            centeredSlides: true,
            slidesPerView: 1.5,
            spaceBetween: 24,
          },
          559: {
            centeredSlides: false,
            slidesPerView: 1,
            spaceBetween: 24,
          },
        },
        pagination: {
          el: '.story-slides__pagination',
          type: 'custom',
          renderCustom(swiper, current, total) {
            const currentText = current < 10 ? '0' + current : current;
            const totalText = total < 10 ? '0' + total : total;

            return `<span class="story-slides-pagination__active">${currentText}</span>
<span class="story-slides-pagination__delimiter">/</span>
<span class="story-slides-pagination__total">${totalText}</span>`;
          },
        },
        navigation: {
          prevEl: '.story-slides__nav-btn--prev',
          nextEl: '.story-slides__nav-btn--next',
        },
      },
      activeSlideLeftPos: 0,
      cssProps: {
        '--slides-pagination-width': SLIDES_PAGINATION_ELEM_WIDTH + 'px',
        '--slides-nav-btn-size': SLIDES_NAV_BTN_SIZE + 'px',
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperElement?.swiper;
    },
    prevNavBtnLeftPos() {
      return this.activeSlideLeftPos + SLIDES_PAGINATION_ELEM_WIDTH + SLIDES_NAV_BTN_SIZE;
    },
    nextNavBtnLeftPos() {
      return this.prevNavBtnLeftPos + SLIDES_NAV_BTN_SIZE + SLIDES_NAV_BTN_SIZE / 2;
    },
  },
  mounted() {
    this.setActiveSlideLeftPosition();

    this._debounceResize = handleResizeWithDebounce();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this._debounceResize = null;
  },
  methods: {
    handleResize() {
      this._debounceResize(this.setActiveSlideLeftPosition);
    },
    setActiveSlideLeftPosition() {
      if (!this.swiper) {
        return;
      }
      const activeSlide = this.swiper.slides[this.swiper.activeIndex];
      const containerLeftPos = this.swiper.el.getBoundingClientRect().left;
      this.activeSlideLeftPos = Math.floor(activeSlide.getBoundingClientRect().left - containerLeftPos);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-slides {
  position: relative;
  margin: 85px 24px;

  @media (min-width: 560px) {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    display: block;
    width: 100%;
    height: 60vh;
    max-height: 280px;
    object-fit: cover;
    margin: auto;

    @media (min-width: 560px) {
      max-height: 380px;
    }

    @media (min-width: 769px) {
      max-height: 540px;
    }
  }

  .swiper-container {
    max-width: 1400px;
    width: 100%;
    margin: auto;
    padding-bottom: 30px;
  }

  &__pagination {
    bottom: 0;
    justify-content: flex-start;
    width: var(--slides-pagination-width);
    font-family: 'Futura LT Pro Bold', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    color: #363636;
  }

  &-pagination {
    ::v-deep &__delimiter {
      margin: 0 5px;
    }
    ::v-deep &__total {
      color: #a2a2a2;
    }
  }

  &__nav-btn {
    position: absolute;
    bottom: 5px;
    left: 0;
    z-index: 10;
    width: var(--slides-nav-btn-size);
    height: var(--slides-nav-btn-size);
    padding: 0;
    transition: opacity 0.2s;

    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 13px;
      border-color: #363636;
      border-style: solid;
      border-width: 1px 1px 0 0;
    }

    &--prev {
      &::before {
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }
    &--next {
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.story-slide {
  &__title {
    margin-top: 5px;
    font-family: 'Futura LT Pro Book', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 27px;
    color: #363636;
  }
}
</style>
